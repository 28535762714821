

body,html{
    /*font-family: 'Open sans', sans-serif !important;*/
    font-size: 13px;
    font-family: 'Montserrat Alternates', sans-serif !important;
    background: #FFF !important;
    height: 100%;
    width: 100%;
}


label{
    font-weight: 900;
}

.form-control-custom{
    height: 50px !important;
    border-radius:10px  !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    border:1px solid #ccc !important;
}


.nav-link, .nav-tabs .nav-link{
    font-size: 13px;
}

.headerFixed{
    color:#333 !important;
    font-size: 16px;
}

.navbar-brand{
    color:#FFF !important;
    font-size: 12px !important;
    font-weight: 900 !important;
}

.overlay{
    top:0;
    left:0;
    right:0;
    bottom:0;
    position: fixed;
    background: #eee;
}

.bgk {
    background: rgba(0, 0, 0, 0.5);
    overflow: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.login{

    min-height: 400px;
    width: 300px;
    border-radius: 6px;
    background: #FFF;
    border:1px solid #ccc;
    box-sizing: border-box;
    margin : 50px auto;

}

.inputLogin{
    height: 50px;
    border-radius: 30px;
    border:1px solid #FF6600;
}

.btnRadius{
    border-radius: 30px;
}



#ASFAlogo{
    position:fixed;
    bottom: 0;
    left: 0;
    top:0;
    width:300px;
    background: deepskyblue;

}
#logo{
    position: fixed;
    left: 0;
    top: 0;
    height:60px;
    background:orange;
    right: 0;
}
#center{
    position: static;
    left:0;
    top: 10px;
    background: border-box;
    height:900px;
    right:2px;
}


.headerFixed{
    min-height: 60px;
    z-index: 1;
    border-bottom:1px solid #ccc;
    /*box-shadow: 0 0 4px #ccc;
    -moz-box-shadow: 0 0 4px #ccc;
    -webkit-box-shadow: 0 0 4px #ccc;

    -webkit-box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);*/
}


.blockLeft{
    position: absolute;
    bottom: 0;
    left:0;
    height: 100%;
    padding-top:20px;
}

.blockLeft > ul{
    width: 100%;
    padding: 0;
}

.blockLeft > ul li{

    list-style: none;
    padding: 10px 17px;
    width: 100%;
    display: block;
    border-bottom: 1px solid #eee;
}

.blockLeft > ul li a{
    color:#333;
    text-decoration: none;
}

.title{
    background: #FF6600;
    color:#FFF;
}

.blockLeft > ul li:hover{
    cursor: pointer;
    border-right:3px solid #FF6600;
    color:#000;
    font-weight: 900;
}


.boxMain{
    position: absolute;
    top:20px;
    background: #FFF  !important;
    bottom: 0;
    right: 0;
    overflow-y: auto;

}

.header-box-fixed{
    box-shadow: 0 1px 8px rgba(0,0,0,.3);
    -moz-box-shadow: 0 1px 8px rgba(0,0,0,.3);
    -webkit-box-shadow: 0 1px 8px rgba(0,0,0,.3);
    height: 60px;
    background-color: white;
    /*box-shadow: 0 0 4px #ccc;


    -webkit-box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
    */
}

.h-b-search .modal-search{
    position: absolute;
    top: 55px;
    height: 500px;
    width: 100%;
    box-shadow: 0 2px 0 2px #EEE;
    background: #FFF;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: none;
}

.h-b-search input{
    height: 100%;
    width: 100%;
    background: transparent;
    border:0;
    padding:17px 21px;
    outline: none;
    font-weight: 600;

}

.icon-r{
    float: left;
    width: 50px;
    height: 50px;
}

.icon-r:hover{
    cursor: pointer;
}

.icon-r>.fa{
    font-size: 20px;
}

.user-detect{
    border-bottom: 2px solid #28a745;
    font-size: 10px;
    font-weight: 900;
    float: right;
    width: auto;
    height: 40px;
    line-height: 46px;
}

.h-b-search{
    height: 45px;
    width: 620px;
    background: #EEE;
    float: left;
    position: relative;
    border-radius:3px;
}

table thead{
    background: #F1F1F1 !important;
}


.Box {
    background-color: #fff;
    border: 1px solid #d1d5da;
    border-radius: 3px;
    margin-top: 50px;
    position: relative;
}

.Box-header {
    padding: 10px;
    margin: -1px -1px 0 -1px;
    background-color: #f6f8fa;
    border-color: #d1d5da;
    border-style: solid;
    border-width: 1px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.Box-body {
    border-bottom: 1px solid #e1e4e8;
    height: auto;
}

.Box-body {
    padding: 8px 16px;
}

.Box-body:last-of-type {
    margin-bottom: -1px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.end{
    background: #d4edda !important;
}

.run{
    background: #cce5ff !important;
}

.issue{
    background: #f8d7da !important;
}

.border_end{
    border-left : 2px solid #d4edda !important;
}


.border_run{
    border-left : 2px solid #cce5ff !important;
}

.titre-p{
  font-size: 18px;
  font-weight: 700;
}
